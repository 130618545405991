<script setup>
import { computed, watch, onMounted, ref } from "vue";
import { useGtag } from "vue-gtag-next";

import AppFormInput from "@/components/Form/AppFormInput";
import AppAlertCard from "@/components/AppAlertCard";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import AppAlert from "@/components/AppAlert";
import { axios } from "@/utils/axios";
import { store } from "@/store";
import { encrypt } from "@/utils/crypto";

import useApi from "@/composables/useApi";

const { user } = useApi();

const { event } = useGtag();

const formSent = ref(false);
const pass1 = ref("");
const pass2 = ref("");
const input1 = ref(null);
const input2 = ref(null);
const loading = ref(false);

const is_error = ref(false);

const profile = computed(() => store.user);

const isValid = computed(() => {
  return pass1.value === pass2.value;
});

const submitForm = async (e) => {
  try {
    loading.value = true;
    const formDataObject = {};
    const formData = new FormData();
    const body = new FormData(e.target);

    body.forEach((value, key) => (formDataObject[key] = value));

    // formDataObject.current_password = encrypt(formDataObject.current_password);
    // formDataObject.new_password = encrypt(formDataObject.new_password);
    // formDataObject.confirm_password = encrypt(formDataObject.confirm_password);

    for (var key in formDataObject) {
      formData.append(key, formDataObject[key]);
    }

    const result = await user.changePassword(profile.value.id, formDataObject.current_password, formDataObject.new_password, formDataObject.confirm_password);
    // console.log(result);
    if (result) {
      is_error.value = false;
      formSent.value = true;
      event("password_changed", {
        event_category: "password_changed",
        event_label: "success",
      });
    }else{
      throw new Error("Failed to change password");
    }
  } catch (error) {
    is_error.value = true;
    event("password_changed", {
      event_category: "password_changed",
      event_label: "error",
    });
  } finally {
    loading.value = false;
  }
};

watch(isValid, (isValid) => {
  let i1 = input1.value.$refs.refInput.textInput;
  let i2 = input2.value.$refs.refInput.textInput;
  i1.setCustomValidity(isValid ? "" : "hey");
  i2.setCustomValidity(isValid ? "" : "hey");
});

onMounted(async () => {
  store.APP_LOADING = false;
});
</script>

<template lang="pug">
AppCard(v-if="!formSent")
  form.pb-8(@submit.prevent="submitForm")
    AppFormInput(type="password" :label="$t('changePass.current')" name="current_password" required)
    AppFormInput(type="password" ref="input1" :label="$t('changePass.new')" name="new_password" @input="pass1 = $event.target.value" required)
    AppFormInput(type="password" ref="input2" :label="$t('changePass.confirm')" name="confirm_password"  @input="pass2 = $event.target.value" required)
    AppButton(:loading="loading" :label="$t('common.send')")
AppAlertCard(:title="$t('changePass.success_title')" :subtitle="$t('changePass.success_subtitle')" v-else)
AppAlert.mb-2.mt-4(:title="$t('changePass.error_title')" :subtitle="$t('changePass.error_subtitle')" v-if="is_error")
</template>

